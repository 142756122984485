<template>
  <FormModal
    title="Type schilderwerk"
    show-back-btn
  >
    <template #body>
      <WhatNeedsPaintingFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import WhatNeedsPaintingFormStep from '~/components/form/steps/whatNeedsPainting/WhatNeedsPaintingFormStep'

export default {
  components: {
    WhatNeedsPaintingFormStep,
    FormModal
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data () {
    return {
      headTitle: 'Type schilderwerk',
      headDescription: 'Geef aan wat geschilderd moet worden.',
      path: '/offertes-aanvragen/type-schilderwerk',
      progressValue: 60,
      checkoutStep: 3
    }
  }
}
</script>
