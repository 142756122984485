<template>
  <WhatNeedsPaintingField
    :choices="choices"
    v-on="$listeners"
  />
</template>

<script>
import WhatNeedsPaintingField from 'chimera/all/components/form/fields/whatNeedPainting/WhatNeedsPaintingField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'WhatNeedsPaintingFormPart',

  components: {
    WhatNeedsPaintingField
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      choices: [
        new Selectable(this.$i18n.t('frames'), this.$i18n.t('frames'), this.$i18n.t('frames')),
        new Selectable(this.$i18n.t('doors'), this.$i18n.t('doors'), this.$i18n.t('doors')),
        new Selectable(this.$i18n.t('walls'), this.$i18n.t('walls'), this.$i18n.t('walls')),
        new Selectable(this.$i18n.t('ceiling'), this.$i18n.t('ceiling'), this.$i18n.t('ceiling')),
        new Selectable(this.$i18n.t('dormer'), this.$i18n.t('dormer'), this.$i18n.t('dormer')),
        new Selectable(this.$i18n.t('stairs'), this.$i18n.t('stairs'), this.$i18n.t('stairs')),
        new Selectable(this.$i18n.t('unknown'), this.$i18n.t('unknown'), this.$i18n.t('unknown')),
        new Selectable(this.$i18n.t('global.other'), this.$i18n.t('global.other'), this.$i18n.t('global.other'))
      ]
    }
  }
}
</script>

<i18n>
{
  "nl-NL": {
    "frames": "Kozijnen",
    "doors": "Deuren",
    "walls": "Muren",
    "ceiling": "Plafonds",
    "dormer": "Dakkapel",
    "stairs": "Trap",
    "unknown": "Weet ik niet"
  }
}
</i18n>
